<style>
.wrapper * {
  box-sizing: border-box;
}
.wrapper {
  padding-left: 20px;
  padding-right: 20px;
  background: #4a4a4a;
  min-height: 100vh;
}
.wrapper * {
  -webkit-font-smoothing: antialiased;
}
.container {
  padding-top: 0;
}
.controls {
  display: block;
}
.inner {
}
.intro {
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.4;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.heading {
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: center;

  color: #fff;
  font-weight: 800;
  line-height: 1;
}

.headingChoose {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}

.headingChoose::before {
  content: "";
  display: inline-block;
  width: 34px;
  height: 28px;
  background-size: cover;
  background-image: url("/arrow.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
}
.selectWrapper {
  width: 100%;
  position: relative;

  --inputFontSize: 1.375rem;
  --height: 50px;
}
.selectWrapper :global(input) {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.selectWrapper :global(*) {
  font-family: "Open Sans", sans-serif;
}

.selectWrapper::after {
  content: "";
  width: 24px;
  height: 20px;
  background: url("/down-caret.svg") no-repeat;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.selectWrapper :global(.selectedItem) {
  font-size: 1.375rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.map {
  display: none;
}

.results {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
}

.resultsHeading {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: #08e78a;
  letter-spacing: -0.63px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}
.secondaryHeading {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 0.9rem;
  color: #424242;

  line-height: 1;

  padding: 0;
  margin: 0;
  padding-top: 15px;
}
.switchContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}
.toggleWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.toggleSwitch {
  padding-left: 10px;
  padding-right: 10px;
}
.toggleWrapperItem {
  font-size: 0.75rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.table {
  border-collapse: collapse;
  font-family: "Saira Condensed", sans-serif;
  width: 100%;
}

.tableRow {
  text-align: center;
}
.tableRow td:nth-child(1) {
  font-family: "Saira Condensed", sans-serif;
}

.tableRow td:nth-child(3),
.tableRow th:nth-child(3) {
  color: #3e9acd;
}
.tableRow td:nth-child(4),
.tableRow th:nth-child(4) {
  color: #10c679;
}
.tableHead {
  font-size: 2rem;
}

.tableCellHeading {
  position: relative;
  font-size: 1rem;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}

.tableHeadingUnit {
  letter-spacing: 1px;
  position: absolute;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg) translateY(50%);
  white-space: nowrap;
  top: 50%;
  font-size: 0.4rem;
  line-height: 1;
}
.tableCell {
  text-align: center;
  padding-top: 15px;
  font-size: 0.96rem;
  font-family: "Saira Condensed", sans-serif;
  font-weight: 600;
}
.tableCellModelName {
  text-align: left;
  font-family: "Saira Condensed", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
}

.tableCellModelText {
  font-weight: 700;
  color: #595959;
  opacity: 0.5;
  font-size: 1rem;
  text-align: left;
  font-family: "Saira Condensed", sans-serif;
}
.sources {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.sourcesLink {
  color: #fff;
}

.vehicleIcon {
  display: none;
}
.caveat {
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
}
.instructions {
  position: absolute;
  right: 40px;
  width: 290px;
}
.instructionText {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.15rem;
  color: #fff;
}

.instructionImage {
  max-width: 100%;
  margin-top: 80px;
}
.resultsWrapper {
  position: relative;
}

@media (min-width: 992px) {
  .container {
    position: relative;
    max-width: 1078px;
    margin: 0 auto;
    padding-top: 60px;
  }
  .caveat {
    font-size: 1rem;
  }

  .tableCell {
    font-size: 1.125rem;
  }
  .tableCellModelText {
    font-size: 1.5rem;
  }
  .tableCellModelName {
    font-size: 1rem;
  }
  .controls {
    display: none;
  }

  .instructionText {
    text-align: right;
    font-size: 1.2rem;
  }
  .selectWrapper {
    display: none;
  }
  .results {
    padding: 20px;
  }
  .tableCellHeading {
    font-size: 1.8rem;
  }
  .resultsWrapper {
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .vehicleIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .resultsHeading {
    text-align: left;
    font-size: 2.5rem;
  }
  .secondaryHeading {
    font-size: 1rem;
    padding-bottom: 5px;
  }
  .map {
    display: block;
  }
  .switchContainer {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .tableHeadingUnit {
    font-size: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .resultsWrapper {
    width: 453px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .results {
    padding: 30px;
  }
  .resultsHeading {
    font-size: 3rem;
  }
  .secondaryHeading {
    font-size: 1.25rem;
  }
}
</style>

<script>
import Header from "./Header.svelte";
import Map from "./Map.svelte";
import Select from "svelte-select";
import Switch from "./Switch.svelte";
import { fly } from "svelte/transition";
import data from "./data.json";

let activeVehicleData = {};
let view = "average_price";
let activeLocation;
let resultsElement;

let items = [
  { value: "london", label: "London" },
  { value: "birmingham", label: "Birmingham" },
  { value: "glasgow", label: "Glasgow" },
  { value: "bristol", label: "Bristol" },
  { value: "manchester", label: "Manchester" },
  { value: "cardiff", label: "Cardiff" },
  { value: "leeds", label: "Leeds" },
  { value: "newcastle", label: "Newcastle" },
  { value: "nottingham", label: "Nottingham" },
  { value: "southampton", label: "Southampton" },
];
let selectedValue = { value: "london", label: "London" };

function handleChangeLocation(location) {
  location = location.toLowerCase();
  activeLocation = location;
  activeVehicleData = data[location][view];

  resultsElement.scrollIntoView({ behavior: "smooth" });
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function handleChangeView(newView) {
  view = newView;
  handleChangeLocation(activeLocation);
}

function format(str) {
  let formatted = parseInt(str);
  if (isNaN(formatted)) {
    return "N/A";
  }

  return formatted.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}
</script>

<div class="wrapper">
  <div class="container">
    <Header />
    <div class="inner">
      <div class="controls">
        <div class="intro">
          Would you consider buying a second-hand electric vehicle for your next
          car? Check out how much it costs and how many cars are available in
          the city closest to you.
        </div>

        <h3 class="heading headingChoose">Choose a city</h3>

        <div class="selectWrapper">
          <Select
            isClearable="{false}"
            items="{items}"
            on:select="{(selected) =>
              handleChangeLocation(selected.detail.value)}"
            placeholder="Select location..." />
        </div>
      </div>

      <div class="resultsWrapper" bind:this="{resultsElement}">
        {#if activeVehicleData.length}
          <div class="results" in:fly="{{ y: 200, duration: 300 }}">
            <h1 class="resultsHeading">
              {capitalizeFirstLetter(activeLocation)}
            </h1>
            <h2 class="secondaryHeading">
              {#if view === "average_price"}Average price of second-hand vehicle{:else}Availability
                of second-hand vehicle{/if}
            </h2>
            <div class="caveat">(based on distance from city centre)</div>
            <div class="switchContainer">
              <Switch onToggle="{handleChangeView}" />
            </div>

            <table class="table">
              <thead class="tableHead">
                <tr class="tableRow">
                  <th class="tableCellModelText">EV Model</th>
                  <th class="tableCellHeading">
                    0-20 <span class="tableHeadingUnit">miles</span>
                  </th>
                  <th class="tableCellHeading">
                    100 <span class="tableHeadingUnit">miles</span>
                  </th>
                  <th class="tableCellHeading">
                    200 <span class="tableHeadingUnit">miles</span>
                  </th>
                </tr>
              </thead>

              <tbody class="tableBody">
                {#each activeVehicleData as vehicle}
                  <tr class="tableRow">
                    <td class="tableCell tableCellModelName"
                      ><img
                        src="vehicle-icon.svg"
                        alt="Vehicle icon"
                        class="vehicleIcon"
                        aria-hidden="true" />{vehicle[0]}</td>
                    <td class="tableCell"
                      >{#if view === "average_price"}{format(
                          vehicle[1]
                        )}{:else}{vehicle[1]}{/if}</td>
                    <td class="tableCell"
                      >{#if view === "average_price"}{format(
                          vehicle[2]
                        )}{:else}{vehicle[2]}{/if}</td>
                    <td class="tableCell"
                      >{#if view === "average_price"}{format(
                          vehicle[3]
                        )}{:else}{vehicle[3]}{/if}</td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        {/if}
      </div>
      <div class="map">
        {#if !activeVehicleData.length}
          <div class="instructions">
            <p class="instructionText">
              Would you consider buying a second-hand electric vehicle for your
              next car? Check out how much it costs and how many cars are
              available in the city closest to you.
            </p>

            <img
              src="choose-a-city.svg"
              alt="Choose a city"
              class="instructionImage" />
          </div>
        {/if}
        <Map onChangeLocation="{handleChangeLocation}" />
      </div>

      <div class="sources">
        <strong>Source:</strong>{" "}
        <a
          class="sourcesLink"
          href="https://www.autotrader.co.uk/cars/electric"
          rel="noreferrer"
          target="_blank">
          https://www.autotrader.co.uk/cars/electric
        </a>{" "}
        based on used BMW i3, Nissan Leaf, Tesla Model-3, Mercedes EQC, Volkswagen
        ID and Renault Zoe maximum three years old.
      </div>
    </div>
  </div>
</div>
