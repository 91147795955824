<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.price,
.availability {
  font-size: 0.75rem;
  color: #424242;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  opacity: 0.5;
}
.price {
  padding-right: 10px;
}
.availability {
  padding-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #08e78a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #08e78a;
}

input:checked + .slider {
  box-shadow: 0 0 1px #08e78a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
.active {
  opacity: 1;
}
</style>

<script>
export let checked = false;
export let color = "#08E78A";
export let onToggle;

function handleOnChange(e) {
  onToggle(e.target.checked ? "distance_from_cc" : "average_price");
}
</script>

<div class="switchWrapper">
  <span class="price item" class:active="{!checked}">Price</span>
  <label class="switch">
    <input type="checkbox" on:change="{handleOnChange}" bind:checked />
    <span class="slider"></span>
  </label>

  <span class="availability item" class:active="{checked}">Availability</span>
</div>
